<template>
  <div class="mainPage">
    <header-app class="space-mobile"></header-app>
    <slider-component
        class="pb-4"
        :slides="getPhotos()"
        :time="6000"
    />
    <div class="container pt-4 pb-4">
      <h2>O nás</h2>
      <p class="pt-3">Jsme rodinná firma, s dlouholetou tradicí.
      </p>
      <p>
        Zabýváme se:
      </p>
      <ul>
        <li>Zakázkovou výrobou kovových konstrukcí</li>
        <li>Výrobků z nerezové oceli</li>
        <li>Montáž vyrobených konstrukcí</li>
        <li>Pronájem nebytových prostor</li>
      </ul>
    </div>
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10431.52391064264!2d16.6286175!3d49.1838451!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6104b50a61842315!2sMIDUR%20s.r.o.!5e0!3m2!1scs!2scz!4v1639763060374!5m2!1scs!2scz"
        width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    <footer-app id="contact" class="footer-top"></footer-app>
  </div>
</template>

<script>
import Footer from "./Footer";
import Header from "./Header";
import SliderComponent from "./SliderComponent";

export default {
  name: 'HelloWorld',
  components: {
    'footer-app': Footer,
    'header-app': Header,
    'slider-component': SliderComponent
  },
  data() {
    return {
      sliderPhoto: ["/photo/Pasohlávky_2.1.jpg", "/photo/Pasohlávky_3.1.jpg"],
    }
  },
  props: {
    msg: String
  },
  methods: {
    getPhotos: function () {
      return this.sliderPhoto
    }
  }
}
</script>

<style scoped>
.footer-top {
  margin-top: -10px;
}
li {
  list-style-type: square;
}
@media screen and (max-width: 600px) {
  .space-mobile {
    padding-bottom: 8rem;
  }
}
</style>
