<template>
  <div class="container-new">
    <div v-if="this.slides" id="photoSlider">
      <a class="next" v-on:click="nextSlide">❯</a>
      <a class="prev" v-on:click="prevSlide">❮</a>
      <img class="img-fluid mx-auto d-block" :src="this.slides[this.counter]">
    </div>
  </div>
</template>

<script>
export default {
  name: "sliderPhoto",
  props: [
    'slides',
      'time'
  ],
  data() {
    return {
      counter: 0,
    }
  },
  created() {
    setInterval(this.nextProgress, this.time);
  },
  methods: {
    nextSlide() {
      this.counter = (this.counter + 1) % this.slides.length;
    },
    prevSlide() {
      this.counter = (this.slides.length + this.counter - 1) % this.slides.length;
    },
    nextProgress() {
      this.nextSlide()
      this.progress = 0;
    },
  }
}
</script>

<style scoped>

@media screen and (max-width: 400px) {

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    background-color: #cbcbcb;
    top: 50%;
    width: auto;
    padding: 5px;
    margin-top: -22px;
    color: #152326;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
}

@media screen and (max-width: 400px) {

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    background-color: #cbcbcb;
    top: 55%;
    width: auto;
    padding: 5px;
    margin-top: -22px;
    color: #152326;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
}

@media screen and (min-width: 401px) and (max-width: 500px) {

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    background-color: #cbcbcb;
    top: 60%;
    width: auto;
    padding: 5px;
    margin-top: -22px;
    color: #152326;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
}

@media screen and (min-width: 501px) {
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    background-color: #cbcbcb;
    top: 50%;
    width: auto;
    padding: 13px;
    margin-top: -22px;
    color: #152326;
    font-weight: bold;
    font-size: 25px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
}

@media (min-width: 1025px) {
  #photoSlider {
    overflow: hidden;
  }
}

a:hover {
  text-decoration: none;
  color: #ffffff;
}

img {
  width: 100%;
}

.container-new {
  position: relative;
}
</style>
